// DocumentContext.js
import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs"; // Ensure dayjs is imported

export const DocumentContext = createContext();

const filterFormInitialData = {
  statusDate: {
    label: "Create Date",
    value: "CDT",
  },
  searchVal: "",
  for: "1",
  daysOrMonths: "days",
  fromDate: dayjs().subtract(1, "day"),
  toDate: dayjs(),
  location: {
    companyName: "All",
    companyId: "0",
  },
  status: {
    description: "All",
    code: "0",
  },
  subStatus: {
    description: "All",
    type: "0",
  },
  assignedTo: {
    description: "All",
    code: "0",
  },
  dataSource: {
    description: "All",
    code: "0",
  },
  subProcess: {
    description: "All",
    code: "1000",
  },
  batchNumber: "",
};

export const DocumentContextProvider = ({ children }) => {
  const [formData, setFormData] = useState(filterFormInitialData);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const resetFormData = () => {
    setFormData(filterFormInitialData);
    setFiltersApplied(false);
  };

  return (
    <DocumentContext.Provider
      value={{
        formData,
        setFormData,
        filtersApplied,
        setFiltersApplied,
        resetFormData,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

DocumentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
