// TabContext.js
import React, { createContext, useState } from "react";

export const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [rows, setRows] = useState([]);
  const [orbitClaimId, setOrbitClaimId] = useState("");
  const [providerControlNumber, setProviderControlNumber] = useState("");
  const [formData, setFormData] = useState({});
  const [patientControlnumber, setPatientControlNumber] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  // reportsData
  const [globalreportsData, setGlobalReportsData] = React.useState({});

  return (
    <TabContext.Provider
      value={{
        rows,
        setRows,
        paginationModel,
        setPaginationModel,
        formData,
        setFormData,
        rowSelectionModel,
        setRowSelectionModel,
        setOrbitClaimId,
        orbitClaimId,
        setProviderControlNumber,
        providerControlNumber,
        setPatientControlNumber,
        patientControlnumber,
        globalreportsData,
        setGlobalReportsData,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
