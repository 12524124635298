import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import orbitLogin from "../images/orbitLogin.jpg";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { UserLogin } from "../apis/login";
import Loader from "../components/Loader";
import LoaderComp from "../components/LoaderComp";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { baseurl } from "../apis/Urls";
import { LoginAPI } from "../apis/Urls";
import Notification from "../components/Notification";
import { useSearchParams } from "react-router-dom";
import { appVersion } from '../versionValue';

const textfieldStyles = {
  marginTop: "15px",
};

const imageStyles = {
  height: "100vh",
  objectFit: "cover",
};

const heading = {
  color: "#6466E8",
  fontSize: "24px",
  fontWeight: "700",
  wordSoacing: "10px",
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { data, mutateAsync, isLoading } = UserLogin();
  const [searchParams] = useSearchParams();
  var seluserName = "";
  var selpasswordVaible = "";
  var url = window.location.href;
  var queryString = url ? url.split("?")[1] : "";
  queryString = queryString ? queryString.split("&") : "";
  seluserName = queryString
    ? queryString.length > 0
      ? queryString[0]
      : ""
    : "";
  selpasswordVaible = queryString
    ? queryString.length > 1
      ? queryString[1]
      : ""
    : "";

  useEffect(() => {
    // if (values.companyCode == '' && values.userName == '' && values.password == '') {
    //   setNotify({ isOpen: true, message: 'Fill all the details', type: 'error' })
    // }
    if (data && data?.status?.code !== 200) {
      if (data && data?.status?.code == 500) {
        setNotify({
          isOpen: true,
          message: `Internal Server Error`,
          type: "error",
        });
      } else if (data && data?.status?.code == undefined) {
        setNotify({
          isOpen: true,
          message: `Internal Server Error. please contact support team`,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
      }
    }
    // if (data != undefined && data?.status?.code != 200) {
    //   setNotify({
    //     isOpen: true,
    //     message: `${data?.status?.message}`,
    //     type: 'error'
    //   })
    // }
    // else {
    //   setNotify({ isOpen: false, message: '', type: '' })
    // }
    // if(data?)
  }, [data]);

  let schema = yup.object({
    companyCode: yup.string().trim(),
    userName: yup.string().trim(),
    password: yup.mixed(),
  });
  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: {
      companyCode: seluserName,
      userName: selpasswordVaible,
      password: "",
    },
    validationSchema: schema,

    onSubmit: async (values) => {
      // try {
      //   setLoading(true);
      //   const response = await axios.post(`${baseurl}${LoginAPI}`, values);
      //   setLoading(false);
      //   if (response.data.Status.Code == 200) {
      //     sessionStorage.setItem(
      //       "token",
      //       JSON.stringify(response.data.Data.OrganizationSetting)
      //     );
      //     sessionStorage.setItem("details", JSON.stringify(response.data.Data));
      //     navigation("/patient-list");
      //     return response.data.Data;
      //   } else {
      //     toast.error(`${response.data.Status.Message}`);
      //   }
      // } catch (error) {
      //   toast.error(error);
      // }
      if (values.companyCode == "") {
        setNotify({
          isOpen: true,
          message: "Enter company code",
          type: "error",
        });
      } else if (values.userName == "") {
        setNotify({
          isOpen: true,
          message: "Enter username",
          type: "error",
        });
      } else if (values.password == "") {
        setNotify({
          isOpen: true,
          message: "Enter password",
          type: "error",
        });
      } else {
        await mutateAsync({
          companyCode: values.companyCode,
          password: values.password,
          userName: values.userName,
        });
      }
    },
  });

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid
        item
        sm={6}
        className="hidden sm:block flex justify-center items-center"
      >
        <div className="relative">
          <img
            src={orbitLogin}
            alt="logo"
            style={imageStyles}
            className="LoginBackgroundImage"
          />
          <div className="overflow-text ">
            Meet the <span style={{ color: "#09c1ea" }}>smarter</span> way to
            capture verify and process insurance
          </div>
        </div>
      </Grid>
      <Grid item sm={6} gap={4} className="flex justify-center items-center">
        <div className="md:w-80 mx-6 logintaleWindow">
          <div className="mb-2 flex justify-between items-center">
            <div className="mb-1">
              <p style={heading}>Sign in to Orbit</p>
            </div>
            <div>
              <Typography
                variant="caption"
                display="block"
                className="!font-semibold"
              >
                Version {appVersion}
              </Typography>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                style={textfieldStyles}
                className="loginTextfields"
                fullWidth
                id="outlined-basic"
                autoFocus
                label="Company code"
                autoComplete="off"
                variant="outlined"
                size="small"
                value={values.companyCode}
                onChange={handleChange("companyCode")}
                // required
                error={touched.companyCode && errors.companyCode}
              />
              <TextField
                className="loginTextfields"
                fullWidth
                id="outlined-basic-text"
                label="User name"
                variant="outlined"
                size="small"
                autoComplete="off"
                style={textfieldStyles}
                value={values.userName}
                onChange={handleChange("userName")}
                error={touched.userName && errors.userName}
                // required
              />
              <FormControl fullWidth variant="outlined" style={textfieldStyles}>
                <InputLabel htmlFor="outlined-adornment-password" size="small">
                  Password
                </InputLabel>
                <OutlinedInput
                  value={values.password}
                  onChange={handleChange("password")}
                  error={touched.password && errors.password}
                  id="outlined-adornment-password"
                  autoComplete="off"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>
            {/* <div className="flex justify-end">
              <a
                href="/"
                className="text-sm mt-2 font-normal forgot-password-styles"
              >
                Forgot password?
              </a>
            </div> */}

            <div className="mt-6">
              <button
                className="login-button"
                type="submit"
                disabled={isLoading}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </Grid>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default Login;
