import { Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = (props) => {
  const { notify, setNotify } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };

  useEffect(() => {
    () => {
      setNotify({
        ...notify,
        isOpen: false,
      });
    };
  }, []);

  return (
    <Snackbar
      open={notify.isOpen}
      autoHideDuration={
        notify?.message?.length > 50
          ? notify?.message?.length > 100
            ? 45000
            : 5000
          : 3000
      }
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={notify.type}
        sx={{ width: "100%", fontWeight: "600", fontSize: "0.92rem" }}
      >
        {notify.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
