import React, { useState, useEffect } from "react";

const UpdateNotification = ({ registration }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const waitingServiceWorker = registration?.waiting;

    if (waitingServiceWorker) {
      setShow(true);

      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  }, [registration]);

  const handleUpdate = () => {
    registration?.waiting.postMessage({ type: "SKIP_WAITING" });
  };

  return (
    show && (
      <div style={notificationStyle}>
        <p>A new version of the app is available.</p>
        <button onClick={handleUpdate}>Update Now</button>
      </div>
    )
  );
};

const notificationStyle = {
  position: "fixed",
  bottom: "20px",
  left: "20px",
  background: "#f1c40f",
  padding: "10px",
  borderRadius: "5px",
  zIndex: 1000,
};

export default UpdateNotification;
