import React from "react";
import "ag-grid-enterprise";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import * as serviceWorker from "./serviceWorker.js";
import UpdateNotification from "./UpdateNotification.js"; // Import the component

const root = ReactDOM.createRoot(document.getElementById("root"));
const client = new QueryClient();

const onServiceWorkerUpdate = (registration) => {
  root.render(
    <ErrorBoundary
      fallback={
        <div>
          <h1>Data loading failed. Please contact support team.!!</h1>
        </div>
      }
    >
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <App />
          <UpdateNotification registration={registration} />
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

root.render(
  <ErrorBoundary
    fallback={
      <div>
        <h1>Data loading failed. Please contact support team.!!</h1>
      </div>
    }
  >
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </ErrorBoundary>
);

// Register the service worker with the onUpdate function
serviceWorker.register({
  onUpdate: onServiceWorkerUpdate,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
