import axios from "axios";
import { baseurl } from "./Urls";

let token = JSON.parse(sessionStorage?.getItem("token"));
let details = JSON.parse(sessionStorage?.getItem("details"));
export const instance = axios.create({
  baseURL: baseurl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `${details?.orgId}`,
    "x-location": details?.companyId ? `${details?.companyId}` : `""`,
  },
});

export const Blobinstance = axios.create({
  baseURL: baseurl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    "x-ms-blob-type": "Blockblob",
  },
});

// export const postinstance = axios.create(
//     {
//         baseURL: baseurl,
//         headers: {
//             "Content-Type": "multipart/form-data; boundary=add-random-characters"
//         }
//     }
// )
