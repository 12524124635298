import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({
  buttonType = "button",
  text,
  navigate,
  filled = false,
  onclick,
  classname,
  disable = false,
}) => {
  const navigation = useNavigate();

  const navigationHandler = () => {
    navigation(navigate);
  };

  const filledOnClickHandler = () => {
    onclick();
    if (navigate) {
      navigation(navigate);
    }
  };

  return (
    <div>
      <button
        type={buttonType ? "button" : buttonType}
        // className={!filled ? "button-styles" : "filled-button-styles"}
        disabled={disable}
        className={classname}
        variant="outlined"
        onClick={filled ? filledOnClickHandler : navigationHandler}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
